<template>
    <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
    <section class="chat-app-window ai-chat-app-window">
        <!-- Chat Content -->
        <div class="active-chat w-100">
            <!-- User Chat Area -->
            <div ref="refChatLogPS" class="ai-chat-scroll" @scroll="scrollHandle">
                <AiChatLog :chats="chats" />
            </div>

            <!-- Message Input -->
            <b-form class="chat-app-form chat-msg-box" @submit.prevent="askQuestion">
                <b-input-group class="input-group-merge form-send-message mr-1">
                    <b-form-input :disabled="loading" v-model="message" :placeholder="$t('Enter your message')" />
                </b-input-group>
                <b-button variant="primary" type="submit" :disabled="isEmpty(message) || loading">
                    {{ $t('Send') }}
                </b-button>
            </b-form>
        </div>
    </section>
</template>
  
<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
  
<script>
import AiChatLog from '@/views/users/ai/AiChatLog.vue'
import { mapActions } from 'vuex'
import { isEmpty } from '@/utilities'
import { showLoader, hideLoader, showDangerNotification, showErrorNotification } from '@/@core/comp-functions/ui/app'
export default {
    name: 'AIChat',

    components: {
        AiChatLog
    },

    props: {

    },

    data: () => ({
        perfectScrollbarSettings: {
            maxScrollbarLength: 50,
            scrollTo: 0
        },
        message: null,
        chats: [],
        maxScroll: [],
        loading: false,
    }),

    computed: {

    },
    mounted() {
        this.getChats().then(r => {
            this.chats = r
        })
    },
    methods: {
        isEmpty,
        scrollHandle(e){
            const {originalTarget} = e
            const {scrollHeight, scrollTop, clientHeight} = originalTarget
            if(!isEmpty(scrollHeight) && !isEmpty(scrollTop) && !isEmpty(clientHeight)){
                const currentScroll = scrollTop
                const maxScroll = scrollHeight - clientHeight
                this.maxScroll = maxScroll
            }
        },
        ...mapActions('ai', [
            'getChats',
            'sendMessage'
        ]),
        askQuestion(){
            if(!isEmpty(this.message)){
                showLoader()
                this.loading = true
                this.chats.push({
                    byUser: true,
                    messages: [
                        {
                            msg: this.message,
                            time: new Date,
                        }
                    ]
                })
                const payLoad = {
                    question: this.message
                }
                this.message = null;
                this.sendMessage(payLoad).then(r => {
                    const {_statusCode, responseData, message} = r
                    if(_statusCode === 203){
                        this.$swal({
                            title: this.$t(message),
                            iconHtml: this.$helpers.swalIcon('alert.png'),
                            showCancelButton: true,
                            confirmButtonText: this.$t('Upgrade'),
                            cancelButtonText: this.$t('Later'),
                            customClass: {
                                confirmButton: 'btn btn-success',
                                cancelButton: 'btn btn-danger ml-1',
                                icon: 'border-0',
                            },
                            buttonsStyling: false,
                        }).then(result => {
                            if(result?.isConfirmed){
                                this.$router.push({
                                    name: 'user-profile', query: {
                                        tab: 'subscription'
                                    }
                                })
                            }
                        })
                    }else{
                        if(Array.isArray(responseData)){
                            responseData.forEach(msg => {
                                this.chats.push({
                                    byUser: false,
                                    messages: [
                                        {
                                            msg,
                                            time: new Date,
                                        }
                                    ]
                                })
                            })
                            this.perfectScrollbarSettings.scrollTo = this.maxScroll
                        }else if(typeof responseData === 'string'){
                            showErrorNotification(this, responseData)
                        }
                    }
                    
                    this.loading = false
                    hideLoader()
                }).catch(e => {
                    if(e.response){
                        const { status, data } = e.response
                        if(data?.message && status === 500){
                            const { message } = data
                            showErrorNotification(this, message)
                        }
                    }
                    this.loading = false
                    hideLoader()
                });
            }
        },
    },
}
</script>

<style scoped>
.ai-chat-scroll{
    max-height: 85%;
    scroll-behavior: smooth;
    overflow-y: scroll;
}
</style>