<template>
  <div class="chats">
    <template v-if="isEmpty(chats)">
      <div class="d-flex justify-content-center align-items-center align-self-center" style="width: 100%">
        <img :src="`${$apiUrl}/images/common/MOM AI Chat.svg`" style="max-width: 50vw" />
        <br />
      </div>
      <div class="w-100 text-center mt-3">
        <h3 class="text-primary font-weight-bolder">Begin with MOM AI - Let's Go!</h3>
      </div>
    </template>
    <template v-else>
      <div 
        v-for="(msgGrp, index) in chats"
        :key="`chats_${index}`" class="chat"
        :class="{ 'chat-left': !msgGrp.byUser }"
      >
        <div class="chat-avatar">
          <b-avatar 
            size="36"
            class="avatar-border-2 box-shadow-1"
            variant="transparent"
            :src="msgGrp.byUser ? userAvatar : aiAvatar" 
          />
        </div>
        <div class="chat-body">
          <div 
            v-for="(msgData, mk) in msgGrp.messages" 
            :key="`message_${mk}_${index}`" 
            class="chat-content table-responsive table-bordered table border-0"
          >
            <div :class="'float-left'">
              <p style="white-space: pre-wrap" v-html="msgData.msg"></p>
            </div>
            <div :class="'float-right'">
              <b-img
                :id="`chat_${index}_${mk}`"
                :title="'Copy to Clipboard'"
                @click="copyToClipBoard(msgData.msg)"
                :src="require('@/assets/images/app-icons/icons8-copy-48.png')"
                width="24"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

import { isEmpty } from '@/utilities'
import { showErrorNotification, showSuccessNotification } from '@/@core/comp-functions/ui/app'

export default {

  name: 'AiChatLog',

  components: {

  },

  props: {
    chats: Array,
    code: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data: () => ({
    userAvatar: null,
    aiAvatar: null
  }),

  computed: {
  },

  mounted() {
    this.aiAvatar = require('@/assets/images/app-icons/ai/ai-48.png')
    let userData = localStorage.getItem('userData')
    if(!isEmpty(userData)){
      userData = JSON.parse(userData)
      this.userAvatar = `${process.env.VUE_APP_API_BASE_END_POINT}/${userData.picture}`
    }
  },
  methods: {
    isEmpty,
    copyToClipBoard(data) {
      const textarea = document.createElement('textarea');
      textarea.textContent = data;
      document.body.appendChild(textarea);

      const selection = document.getSelection();
      const range = document.createRange();
      range.selectNode(textarea);
      selection.removeAllRanges();
      selection.addRange(range);

      try {
          if(document.execCommand('copy')){
              showSuccessNotification(this, 'Copied to clipboard');
          }else{
              showErrorNotification(this, "Failed to copy");
          }
      } catch (err) {
          showErrorNotification(this, "Failed to copy");
      }
      document.body.removeChild(textarea);
      selection.removeAllRanges();
    },
  },
  watch: {
    chats: {
      handler(nv, ov){
        if(!isEmpty(nv)){
          const nvLastIndex = nv.length - 1
          const lastMsgGrp = nv.find((i,idx) => idx === nvLastIndex)
          if(!isEmpty(lastMsgGrp)){
            const lastMsgGrpTotalMsgs = lastMsgGrp.messages.length
            if(lastMsgGrpTotalMsgs > 0){
              setTimeout(() => {
                const refId = `chat_${nvLastIndex}_${lastMsgGrpTotalMsgs-1}`
                document.querySelector(`#${refId}`).scrollIntoView({ behavior: 'smooth' });
              }, 500);
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.chat-left{
  position: relative;
}
.chat{
  position: relative;
}
@media screen {
  
}
</style>

<style>
.cp_btn {
  cursor: pointer;
	position: relative;
	bottom: 0;
	right: -95%;
}
.cp_btn_right {
  cursor: pointer;
	position: absolute;
	bottom: 10px;
	right: 80px;
}
@media screen and (max-width: 767px) {
  .chat-content{
    max-width: 92vw;
  }
  .chat{
    max-width: 95vw;
  }
}

</style>